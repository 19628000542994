import type { RawUser } from "@services/scan/types/requests";
import type { Partner, User } from "@services/scan/types/common";

export function prepUser(user?: RawUser): User | null {
  if (!user) {
    return null;
  }

  const firstName = user.first_name ?? "";
  const lastName = user.last_name ?? "";
  const email = user.email ?? "";
  const title = user.title ?? "";
  const partnerName = user.partner_name ?? "";
  const partnerPublicCode = user.partner_public_code ?? "";
  const contractUrl = user.contract_pdf_url ?? "";
  const marketingEnabled = Boolean(user.marketing_enabled);
  const canEditPaymentInfo = Boolean(user.can_edit_payment_information);
  const paymentEnabled = Boolean(user.payment_enabled);
  const nonPatientLedFlow = Boolean(user.non_patient_led_flow_enabled);
  const partnerPricing = user.partner_pricing_type ?? null;
  const specialistConsultationsEnabled = Boolean(
    user.specialist_consultations_enabled
  );
  const partners: Partner[] = (user.partners || []).map((rawPartner) => {
    return { name: rawPartner.name, publicCode: rawPartner.public_code };
  });

  return {
    id: user.id,
    email,
    title,
    firstName,
    lastName,
    partnerName,
    partnerPublicCode,
    contractUrl,
    marketingEnabled,
    canEditPaymentInfo,
    paymentEnabled,
    nonPatientLedFlow,
    partnerPricing,
    specialistConsultationsEnabled,
    partners,
  };
}

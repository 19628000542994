// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails";
// import * as ActiveStorage from "@rails/activestorage";

// ActiveStorage.start();
import React from "react";
import { createRoot } from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BrowserRouter } from "react-router-dom";

import { identifyUser, trackEvent } from "services/analytics";
import decodeJwtToken from "services/decodeJwtToken";
import PortalApp from "apps/PortalApp";

import ViewReferralApp from "apps/ViewReferralApp";
import ImagingGuideApp from "apps/ImagingGuideApp";
import TermsReviewApp from "apps/TermsReviewApp";
import ConfirmUserApp from "apps/ConfirmUserApp";
import { ScrollToTop } from "components/common/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const decodedJwtToken = decodeJwtToken(window.AppData.AccessToken);

Bugsnag.start({
  apiKey: window.AppData.BugsnagApiKey,
  appVersion: window.AppData.AppVersion || undefined,
  releaseStage: window.AppData.ReleaseStage,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "sandbox", "staging", "review_app"],
  metadata: { jwt_token: decodedJwtToken },
});

identifyUser(decodedJwtToken?.user_id);

if (window.AppData.User != null && window.AppData.User.just_signed_in) {
  trackEvent("signed_in");
  if (window.AppData.User.first_sign_in) {
    trackEvent("first_sign_in");
  }
}

const now = Math.round(Date.now() / 1000);

window.AppData.AccessTokenExpiresAt = now + window.AppData.AccessTokenExpiresIn;

const queryClient = new QueryClient();
const isDev = window.AppData.ReleaseStage === "development";

document.addEventListener("turbo:load", () => {
  [
    {
      node: "portal-app",
      renderComponent: (props) => (
        <BrowserRouter>
          <ScrollToTop />
          <PortalApp {...props} />
        </BrowserRouter>
      ),
    },
    {
      node: "view-referral-app",
      renderComponent: (props) => (
        <BrowserRouter basename="v">
          <ViewReferralApp {...props} />
        </BrowserRouter>
      ),
    },
    {
      node: "imaging-guide-app",
      renderComponent: (props) => (
        <BrowserRouter basename="imaging_guide">
          <ImagingGuideApp {...props} />
        </BrowserRouter>
      ),
    },
    {
      node: "terms-review-app",
      renderComponent: (props) => (
        <BrowserRouter basename="terms_review">
          <TermsReviewApp {...props} />
        </BrowserRouter>
      ),
    },
    {
      node: "confirm-user-app",
      renderComponent: (props) => (
        <BrowserRouter basename="confirm_user">
          <ConfirmUserApp {...props} />
        </BrowserRouter>
      ),
    },
  ].forEach((nodeConfig) => {
    const node = document.getElementById(nodeConfig.node);

    if (node !== null) {
      const ErrorBoundary =
        Bugsnag.getPlugin("react")?.createErrorBoundary(React) ||
        React.Fragment;
      const root = createRoot(node);
      root.render(
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            {isDev && <ReactQueryDevtools initialIsOpen={false} />}
            {nodeConfig.renderComponent(node.dataset)}
          </QueryClientProvider>
        </ErrorBoundary>
      );
    }
  });
});

import { Popover } from "@headlessui/react";
import { LoadingSpinner } from "@scandotcom/react";
import { User } from "@services/scan";
import { IconChevronDown } from "@tabler/icons";
import classNames from "classnames";
import React, { useState } from "react";

interface Props {
  className?: string;
  user: User;
}

export const PartnerSwitcher = ({ className, user }: Props) => {
  const [loading, setLoading] = useState(false);

  const onSwitchPartners = () => {
    setLoading(true);
  };

  const menuStyles = classNames(
    "absolute origin-top-right focus:outline-none border",
    "bg-white text-neutral-900 shadow-lg border-neutral-200",
    "right-0 mt-2 rounded-lg py-4 px-2.5 z-[100] gap-y-1 grid min-w-[180px]"
  );

  const wrapperStyles = classNames(
    "relative text-sm font-maison font-medium",
    className
  );

  return (
    <Popover
      as="form"
      data-turbo="false"
      method="post"
      action="/users/switch"
      className={wrapperStyles}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={window.AppData.CsrfToken}
      />
      <input
        type="hidden"
        name="return_path"
        value={document.location.pathname}
      />

      <Popover.Button
        className="flex items-center gap-2 py-3 px-4 focus:outline-none"
        disabled={loading}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {user.partnerName}
            <IconChevronDown height={20} />
          </>
        )}
      </Popover.Button>

      <Popover.Panel as="fieldset" className={menuStyles}>
        <legend className="float-left mb-2 px-2 text-base">
          Provider account
        </legend>
        {user.partners.map((partner) => (
          <button
            type="submit"
            name="partner_public_code"
            onClick={onSwitchPartners}
            key={partner.publicCode}
            value={partner.publicCode}
            className={classNames(
              "flex rounded-lg py-1.5 px-2 hover:bg-neutral-300",
              user.partnerPublicCode === partner.publicCode && "bg-neutral-200"
            )}
          >
            {partner.name}
          </button>
        ))}
      </Popover.Panel>
    </Popover>
  );
};
